.carousel {
  padding: 5vh;
}
.carousel__item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.carousel__image-container {
  width: 486px;
  display: flex;
  justify-content: center;
}
.carousel__image {
  width: 100%;
  height: 80vh;
  display: block;
  margin: 0 20px 20px 0;
}
.carousel__image img {
  width: 100%;
  display: block;
}
.carousel__info {
  position: relative;
  width: 408px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.carousel__info h2 {
  margin: 0 0 30px;
  text-align: left;
}
.carousel .swiper-pagination {
  left: calc(50% + 20px);
  bottom: 125px;
}
.carousel .swiper-pagination-bullet {
  width: 29px;
  height: 29px;
  border-radius: 0;
  margin-right: 11px;
  opacity: 1;
}
.carousel__mobilenav {
  display: none;
}
@media (max-width: 768px) {
  .carousel__item {
    flex-direction: column;
  }
  .carousel__image-container {
    width: 100%;
    order: 2;
    margin-top: 25px;
  }
  .carousel__info {
    width: 100%;
    position: static;
    padding-left: 20px;
    padding-right: 20px;
  }
  .carousel__button {
    left: 20px;
    right: 20px;
  }
  .carousel .swiper-pagination {
    display: none;
  }
}

.lineup .swiper-container {
  max-width: 1098px;
  margin: 0 auto;
}
.lineup .swiper-wrapper {
  padding: 20px 0;
}
.lineup__item {
  padding-bottom: 30px;
}
.lineup__item:hover .lineup__iteminner {
  box-shadow: 10px 5px 25px 0 rgba(0, 0, 0, 0.2);
}
.lineup__item:hover .lineup__iteminner .lineup__arrow img {
  opacity: 1;
}
.lineup__item .product__image-container {
  border: none;
}
.lineup__item .product__prices {
  display: none;
}
.lineup__item .product__title {
  text-align: center;
}
.lineup__arrow {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -8px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.lineup__arrow img {
  transition: opacity 0.5s;
  opacity: 0;
}
.lineup__iteminner {
  width: 90%;
  margin: 0 auto;
  padding: 0 0 80px;
  transition: box-shadow 0.3s, border 0.3s;
}
@media (max-width: 768px) {
  .lineup .lineup__iteminner {
    padding-bottom: 40px;
  }
}