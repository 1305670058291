.photoalbum {
  max-width: 996px;
  margin: 0 auto;
}
.photoalbum .contentwrapper {
  position: relative;
}
.photoalbum__galleries {
  position: relative;
}
.photoalbum__image {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.photoalbum__gallery-top,
.photoalbum__gallery-top .swiper-wrapper {
  width: 100%;
  height: 600px;
}
.photoalbum__gallery-top .swiper-button-prev,
.photoalbum__gallery-top .swiper-button-next {
  display: none;
  background-image: url("~@mod-pazzie_corporate/webdesigns/pazzie/web/img/slideshow-next.svg") no-repeat;
}
.photoalbum__gallery-bottom {
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  padding: 27px 0 0;
}
.photoalbum__gallery-bottom .photoalbum__navbutton {
  position: static;
}
.photoalbum__thumbs,
.photoalbum__thumbs .swiper-wrapper {
  width: 693px;
}
.photoalbum__thumbitem {
  height: 169px;
  cursor: pointer;
}
.photoalbum__thumbitem:not(.swiper-slide-active) {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}
.photoalbum__navigation {
  flex: 1;
}
@media (max-width: 768px) {
  .photoalbum__gallery-top,
  .photoalbum__gallery-top .swiper-wrapper {
    max-height: 65vh;
  }
  .photoalbum__gallery-top .swiper-button-prev,
  .photoalbum__gallery-top .swiper-button-next {
    display: block;
  }
  .photoalbum__gallery-bottom {
    display: none;
  }
  .photoalbum__mobilenav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
  }
  .photoalbum__mobilenav--prev {
    transform: translateY(-50%) scaleX(-1);
  }
}