.footer {
  background: #ededed;
  height: 150px;
  display: flex;
  align-items: center;
  font-size: 12px;
}
.footer,
.footer a {
  font-family: "FuturaLTPro-Medium", sans-serif;
  font-weight: 500;
}
.footer a {
  text-decoration: none;
}
.footer a:hover {
  text-decoration: underline;
}
.footer__cols {
  display: flex;
  flex-direction: row;
}
.footer__col--1 {
  width: 25%;
  padding-left: 102px;
}
.footer__col--2 {
  width: 50%;
}
.footer__col--3 {
  flex: 1;
  text-align: right;
}
.footer__col--3 .footer__line1 {
  color: #4a4a4a;
}
.footer__line1, .footer__line2 {
  display: block;
}
.footer__line1 {
  color: #0095DE;
}
.footer__sticky, .footer__line2 {
  color: #adadad;
  font-size: 10px;
}
.footer__icon {
  margin-right: 10px;
  position: relative;
  top: 2px;
}
.footer__contactitem {
  margin: 0 39px 0 0;
}
.footer__contactitem:last-child {
  margin-right: 0;
  white-space: nowrap;
}
.footer__socials {
  margin-left: 8px;
}
.footer__socials a {
  margin-left: 10px;
}
@media (max-width: 1200px) {
  .footer {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media (max-width: 1024px) {
  .footer__col--1 {
    padding-left: 0;
  }
}
@media (max-width: 768px) {
  .footer {
    height: auto;
    padding: 25px;
  }
  .footer__cols {
    display: block;
  }
  .footer__col {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
  .footer__col--1 {
    padding-left: 0;
  }
  .footer__col--3 {
    margin-bottom: 0;
  }
  .footer__contactitem {
    display: block;
    width: 100%;
  }
  .footer__socials svg {
    width: 15px;
    height: 15px;
  }
}