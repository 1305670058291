.p-content {
  margin: 0 0 100px;
}
.p-content .p-pageheader {
  height: 820px;
  max-height: 70vh;
  display: flex;
  align-items: center;
  position: relative;
}
.p-content .p-pageheader .contentwrapper {
  position: relative;
  z-index: 1;
}
.p-content .p-pageheader__overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
}
.p-content .p-pageheader__text {
  padding-left: 204px;
  max-width: 690px;
}
.p-content .p-pageheader__text > h1,
.p-content .p-pageheader__text > h2,
.p-content .p-pageheader__text > h3,
.p-content .p-pageheader__text > .intro,
.p-content .p-pageheader__text > .normal,
.p-content .p-pageheader__text > ul,
.p-content .p-pageheader__text > ol {
  color: #fff;
}
.p-content .p-pageheader__text .embeddedobject--site {
  margin: 0;
}
.p-content .p-rtd__rtd > h1,
.p-content .p-rtd__rtd > h2,
.p-content .p-rtd__rtd > h3,
.p-content .p-rtd__rtd > .intro,
.p-content .p-rtd__rtd > .normal,
.p-content .p-rtd__rtd > ul,
.p-content .p-rtd__rtd > ol {
  max-width: 588px;
  margin: 0 auto 20px;
}
.p-content .breadcrumb {
  max-width: 996px;
  margin: 20px auto 80px;
}
@media (max-width: 1024px) {
  .p-content .p-path,
  .p-content .p-rtd {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media (max-width: 768px) {
  .p-content .p-path .breadcrumb {
    margin-bottom: 30px;
  }
  .p-content .p-pageheader__text {
    padding-left: 0;
    margin: 0 auto;
    max-width: 60vw;
  }
}
@media (max-width: 576px) {
  .p-content .p-pageheader__text {
    margin: 0 auto;
    max-width: 100vw;
    padding-left: 25px;
    padding-right: 25px;
  }
}