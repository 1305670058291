.p-home .p-intro__title {
  position: absolute;
  top: calc((100vh - 200px) / 2);
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  line-height: 120%;
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.06);
  text-align: center;
  width: 80vw;
  margin: 0;
}
.p-home .p-intro__bottomcontainer {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: flex-end;
}
.p-home .p-intro__bottomblock, .p-home .p-intro__bottomblock-title, .p-home .p-intro__bottomblock-subtitle {
  transition: background 0.3s, color 0.3s;
}
.p-home .p-intro__bottomblock {
  background: #fff;
  height: 200px;
  width: 400px;
  padding: 0 60px;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  justify-content: center;
}
.p-home .p-intro__bottomblock:hover, .p-home .p-intro__bottomblock--blue {
  background: #0095DE;
  color: #fff;
}
.p-home .p-intro__bottomblock:hover .p-intro__bottomblock-title,
.p-home .p-intro__bottomblock:hover .p-intro__bottomblock-subtitle, .p-home .p-intro__bottomblock--blue .p-intro__bottomblock-title,
.p-home .p-intro__bottomblock--blue .p-intro__bottomblock-subtitle {
  color: #fff;
}
.p-home .p-intro__bottomblock:hover .p-intro__bottomblock-arrow, .p-home .p-intro__bottomblock--blue .p-intro__bottomblock-arrow {
  opacity: 1;
}
.p-home .p-intro__bottomblock:hover .p-intro__bottomblock-arrow .iconcolor--longarrow, .p-home .p-intro__bottomblock--blue .p-intro__bottomblock-arrow .iconcolor--longarrow {
  fill: #fff;
}
.p-home .p-intro__bottomblock--blue:hover {
  color: #0095DE;
  background: #fff;
}
.p-home .p-intro__bottomblock--blue:hover .p-intro__bottomblock-title,
.p-home .p-intro__bottomblock--blue:hover .p-intro__bottomblock-subtitle {
  color: #0095DE;
}
.p-home .p-intro__bottomblock--blue:hover .iconcolor--longarrow {
  fill: #0095DE !important;
}
.p-home .p-intro__bottomblock-title {
  font-family: "FuturaLTPro-Medium", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #000;
}
.p-home .p-intro__bottomblock-subtitle {
  font-family: "FuturaLTPro-Book", sans-serif;
  font-weight: 300;
  font-size: 14px;
  color: #adadad;
}
.p-home .p-intro__bottomblock-arrow {
  margin-left: 8px;
  opacity: 0;
  transition: opacity 0.3s;
}
.p-home .p-intro__bottomblock-arrow .iconcolor--longarrow {
  fill: #0095DE;
}
@media (max-width: 1200px) {
  .p-home .p-intro__bottomblock {
    width: 30vw;
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media (max-width: 1024px) {
  .p-home .p-intro__bottomblock {
    width: 33vw;
  }
  .p-home .p-intro__bottomblock:last-child {
    width: 34vw;
  }
}
@media (max-width: 840px) {
  .p-home .p-intro__bottomblock-arrow {
    display: none;
  }
}
@media (max-width: 576px) {
  .p-home .p-intro .p-intro__bottomcontainer {
    display: none;
  }
  .p-home .p-intro .p-intro__title {
    top: 50%;
  }
}
.p-home .p-wearepazzie__text .embeddedobject--site {
  margin: 0;
}
.p-home .p-wearepazzie__text .textimagecols__image {
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.45);
}
.p-home .p-news {
  padding: 0 25px;
}
.p-home .p-news__container {
  max-width: 996px;
  background: #fff;
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.16);
  margin: 0 auto;
  height: 550px;
  position: relative;
}
.p-home .p-news__leftpart {
  padding: 65px 0 0;
  width: 446px;
}
.p-home .p-news__leftpart > * {
  padding-left: 78px;
  padding-right: 78px;
}
.p-home .p-news__rightpart {
  width: 550px;
}
.p-home .p-news__date {
  color: #BEBEBE;
}
.p-home .p-news__title {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  max-width: 100%;
  height: 264px; /* Fallback for non-webkit */
  margin: 0 auto;
  font-size: 36px;
  line-height: 1.2222222222;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.p-home .p-news__title,
.p-home .p-news__title a {
  text-decoration: none;
}
.p-home .p-news__title a:hover {
  text-decoration: underline;
}
.p-home .p-news__slide {
  display: flex;
}
.p-home .p-news__image {
  display: block;
}
.p-home .p-news__navigation {
  position: absolute;
  bottom: 65px;
  left: 78px;
  z-index: 10;
}
.p-home .p-news .swiper-container, .p-home .p-news__slide, .p-home .p-news__image {
  height: 100%;
}
@media (max-width: 768px) {
  .p-home .p-news__leftpart {
    width: 100%;
  }
  .p-home .p-news__rightpart {
    display: none;
  }
}
@media (max-width: 576px) {
  .p-home .p-news__leftpart {
    padding-left: 30px;
    padding-right: 30px;
  }
  .p-home .p-news__navigation {
    left: 30px;
  }
}
.p-home .p-contact__text {
  max-width: 500px;
  margin: 0 auto 150px;
}
.p-home .p-contact__text .embeddedobject--button {
  margin: 50px 0 0;
}
@media (max-width: 576px) {
  .p-home .p-contact__text {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.p-home .p-pageloader {
  z-index: 200000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: transform 1s cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.p-home .p-pageloader.hide {
  transform: translateY(-150vh);
}
.p-home .p-organizations__text {
  width: 384px;
}
.p-home .p-organizations__text .embeddedobject--button {
  margin: 50px 0 0;
}
.p-home .p-organizations .contentwrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.p-home .p-organizations__logos {
  width: 486px;
}
.p-home .p-organizations__logo-container {
  display: inline-block;
  width: 33.3333%;
  margin: 0 0 20px;
}
.p-home .p-organizations__logo-container:nth-child(3n+2) {
  text-align: center;
}
.p-home .p-organizations__logo-container:nth-child(3n+3) {
  text-align: right;
}
.p-home .p-organizations__logo {
  max-width: 100%;
  display: inline-block;
}
@media (max-width: 1024px) {
  .p-home .p-organizations__logos {
    width: 350px;
  }
}
@media (max-width: 850px) {
  .p-home .p-organizations .contentwrapper {
    padding-right: 30px;
  }
}
@media (max-width: 768px) {
  .p-home .p-organizations .contentwrapper {
    display: block;
  }
  .p-home .p-organizations__text, .p-home .p-organizations__logos {
    width: 100%;
  }
  .p-home .p-organizations__logos {
    padding: 40px 0 0;
  }
}
.p-home .p-section6__text {
  max-width: 588px;
}
.p-home .p-section6__images {
  display: flex;
  width: 100%;
  margin: 110px 0 0;
}
.p-home .p-section6__image-container {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  width: 33.3333%;
  text-decoration: none;
  padding: 25px;
  transition: background 0.3s;
}
html.non-touch .p-home .p-section6__image-container--haslink:hover {
  background: #f7f7f7;
}
html.non-touch .p-home .p-section6__image-container--haslink:hover .p-section6__image {
  transform: scale(1.05);
}
html.non-touch .p-home .p-section6__image-container--haslink:hover .p-section6__item-title {
  text-decoration: underline;
}
.p-home .p-section6__image {
  display: inline-block;
  height: 145px;
  margin: 0 0 60px;
  transition: transform 0.3s;
}
.p-home .p-section6__item-title {
  font-family: "FuturaLTPro-Bold", sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  color: #0095DE;
  max-width: 220px;
  text-align: center;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .p-home .p-section6__images {
    flex-direction: column;
  }
  .p-home .p-section6__image-container {
    margin-bottom: 30px;
    width: 100%;
  }
  .p-home .p-section6__images {
    margin-top: 30px;
  }
  .p-home .p-section6__image {
    margin-bottom: 20px;
  }
}
.p-home .p-section7__text {
  max-width: 588px;
}
.p-home .p-section7__ctas {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 110px 0 0;
}
.p-home .p-section7__ctas--flexstart {
  justify-content: flex-start;
}
.p-home .p-section7__ctas--flexstart .p-section7__cta {
  margin-right: 24px;
}
.p-home .p-section7__cta {
  width: 282px;
  max-width: 90vw;
  text-decoration: none;
  padding: 0 0 124px;
  box-shadow: 0 8px 30px 0 rgba(0, 0, 0, 0.16);
  position: relative;
  transition: box-shadow 0.3s;
}
html.non-touch .p-home .p-section7__cta:hover {
  box-shadow: 0 8px 30px 0 rgba(0, 0, 0, 0.6);
}
html.non-touch .p-home .p-section7__cta:hover .p-section7__cta-image {
  transform: scale(1.05);
}
html.non-touch .p-home .p-section7__cta:hover .p-section7__cta-readmore {
  padding-left: 0;
}
html.non-touch .p-home .p-section7__cta:hover .p-section7__cta-readmore::before {
  opacity: 0;
}
.p-home .p-section7__cta-image-container {
  width: 100%;
  height: 201px;
  position: relative;
  overflow: hidden;
}
.p-home .p-section7__cta-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: transform 0.3s;
}
.p-home .p-section7__text .intro {
  color: #0095DE;
}
.p-home .p-section7__textcontainer {
  position: absolute;
  left: 0;
  bottom: 0;
  min-height: 154px;
  background: #fff;
  padding: 48px 24px;
  width: calc(100% - 34px);
}
.p-home .p-section7__cta-title {
  font-family: "FuturaLTPro-Bold", sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #3D3D3D;
  margin: 0 0 10px;
}
.p-home .p-section7__cta-readmore {
  font-family: "FuturaLTPro-Medium", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: #0095DE;
  padding-left: 32px;
  position: relative;
  transition: padding 0.3s;
}
.p-home .p-section7__cta-readmore::before {
  content: "";
  background: #0095DE;
  width: 16px;
  height: 1px;
  position: absolute;
  left: 0;
  top: 8px;
  transition: opacity 0.2s;
}
.p-home .p-section7__cta-row-divider {
  display: none;
  width: 100%;
}
@media screen and (max-width: 1200px) {
  .p-home .p-section7__ctas {
    flex-flow: row wrap;
    padding-left: 25px;
    padding-right: 25px;
    justify-content: center;
  }
  .p-home .p-section7__cta-row-divider {
    display: block;
  }
  .p-home .p-section7__cta {
    margin: 0 13px 26px;
  }
}
@media screen and (max-width: 67px) {
  .p-home .p-section7__ctas {
    flex-direction: column;
  }
  .p-home .p-section7__cta-container {
    margin-bottom: 30px;
    width: 100%;
  }
  .p-home .p-section7__ctas {
    margin-top: 30px;
  }
  .p-home .p-section7__cta {
    margin-bottom: 20px;
  }
}

/* Fullpage styling overwritten */
#fp-nav {
  background: transparent;
  border: 0;
}
#fp-nav.left {
  left: 41px;
}
#fp-nav ul li {
  width: 13px;
  height: 13px;
  margin: 12.5px 0;
}
#fp-nav ul li a span {
  top: 0;
  left: 0;
  margin: 0;
  border: 1px solid #000;
  display: block;
  width: 13px;
  height: 13px;
  background: transparent;
  border-radius: 0;
}
#fp-nav ul li a span::after {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  opacity: 0;
  background: #0095DE;
  transition: opacity 0.3s;
}
#fp-nav ul li a.active span,
#fp-nav ul li:hover a span,
#fp-nav ul li:hover a.active span {
  width: 13px;
  height: 13px;
  margin: 0;
  border-radius: 0;
}
#fp-nav ul li a.active span::after,
#fp-nav ul li:hover a span::after,
#fp-nav ul li:hover a.active span::after {
  opacity: 1;
}
@media screen and (max-width: 1239px) {
  #fp-nav {
    display: none;
  }
}

html.page-home .footer {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s;
}
html.page-home .footer.show {
  opacity: 1;
  pointer-events: auto;
}
html.page-home .p-homesocial {
  opacity: 0;
  pointer-events: none;
  position: fixed;
  bottom: 70px;
  right: 40px;
  z-index: 2000;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: opacity 0.3s;
}
html.page-home .p-homesocial.show {
  opacity: 1;
  pointer-events: auto;
}
html.page-home .p-homesocial a {
  text-decoration: none;
  margin: 12px 0;
}
html.page-home .p-homesocial a svg {
  display: block;
}
html.page-home .p-homesocial a svg .iconcolor {
  fill: #9B9B9B;
}
@media (min-width: 769px) {
  html.page-home .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
  }
}
@media (max-width: 768px) {
  html.page-home .fp-section.fp-table {
    margin-top: 10vh;
    margin-bottom: 10vh;
  }
  html.page-home .fp-section.fp-table,
  html.page-home .fp-section.fp-table .fp-tableCell {
    height: auto !important;
  }
  html.page-home .fp-section.fp-table.p-intro, html.page-home .fp-section.fp-table.p-contact {
    height: 100vh !important;
  }
  html.page-home .fp-section.fp-table.p-intro {
    min-height: 1000px;
    margin-top: 0;
  }
  html.page-home .fp-section.fp-table.p-contact {
    margin-bottom: 0;
  }
  html.page-home .contentwrapper--smaller {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media (max-width: 576px) {
  html.page-home.logo-big .header__logo {
    width: 75px;
    height: 75px;
  }
  html.page-home .header__logo {
    background-image: url("../../web/img/logo-white.2x.png");
  }
  html.page-home .fp-section.fp-table.p-intro {
    height: 90vh !important;
    min-height: initial;
  }
}

html.header-white #fp-nav {
  border-color: #fff;
  transition: border 0.5s;
}
html.header-white #fp-nav ul li a span {
  border-color: #fff;
  transition: background-color 0.5s;
}

html.page-home body {
  overflow-y: hidden;
}