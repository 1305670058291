.ww-cookiebar {
  display: none;
}
.ww-cookiebar.show {
  display: block;
}
.ww-cookiebar__container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 25px;
}
.ww-cookiebar__cols {
  display: flex;
}
.ww-cookiebar__col--text {
  flex: 1;
}
.ww-cookiebar__col--buttons {
  padding-left: 50px;
  display: flex;
  align-items: center;
}
.ww-cookiebar__button {
  margin-right: 15px;
}
.ww-cookiebar__button:last-child {
  margin-right: 0;
}
.ww-cookiebar__text--mobile {
  display: none;
}
.ww-cookiebar__text > *:first-child {
  margin-top: 0;
}
.ww-cookiebar__text > *:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 768px) {
  .ww-cookiebar__cols {
    display: block;
  }
  .ww-cookiebar__col--buttons {
    margin-top: 15px;
    padding-left: 0;
    justify-content: center;
  }
  .ww-cookiebar__text--desktop {
    display: none;
  }
  .ww-cookiebar__text--mobile {
    display: block;
  }
}