html.page-contact .header,
html.page-contact .footer {
  display: none;
}

.p-contact .p-background__col {
  position: fixed;
  width: 50vw;
  top: 0;
  height: 100vh;
  z-index: -1;
}
.p-contact .p-background__col--left {
  left: 0;
  background: #CAC9C9;
}
.p-contact .p-background__col--right {
  right: 0;
}
@media (max-width: 768px) {
  .p-contact .p-background__col--left {
    width: 100vw;
  }
  .p-contact .p-background__col--right {
    display: none;
  }
}
.p-contact .p-cols {
  display: flex;
}
.p-contact .p-cols__col {
  width: 50%;
}
.p-contact .p-cols__col--left {
  padding: 70px 70px 0 0;
}
.p-contact .p-cols__col--left > h1,
.p-contact .p-cols__col--left > h2,
.p-contact .p-cols__col--left > h3,
.p-contact .p-cols__col--left > .intro,
.p-contact .p-cols__col--left > .normal,
.p-contact .p-cols__col--left > ul,
.p-contact .p-cols__col--left > ol {
  color: #fff;
}
.p-contact .p-cols__col--right {
  font-family: "FuturaLTPro-Medium", sans-serif;
  font-weight: 500;
  padding: 0 0 0 70px;
}
@media (max-width: 768px) {
  .p-contact .p-cols__col--left {
    padding-right: 30px;
  }
  .p-contact .p-cols__col--right {
    width: 0;
  }
}
@media (max-width: 768px) {
  .p-contact .p-cols {
    flex-direction: column;
  }
  .p-contact .p-cols__col {
    width: 100%;
  }
  .p-contact .p-cols__col--left {
    padding: 25px;
    background: #CAC9C9;
  }
}
.p-contact .p-logo {
  display: inline-block;
  margin: 0 0 90px;
}
.p-contact .p-logo img {
  display: block;
}
@media (max-width: 576px) {
  .p-contact .p-logo {
    margin-bottom: 30px;
  }
}
.p-contact .p-contacttypes {
  margin: 40px 0 0;
}
.p-contact .p-contacttypes__type {
  text-decoration: none;
  font-family: "FuturaLTPro-Medium", sans-serif;
  font-weight: 500;
  display: inline-flex;
  font-size: 12px;
  color: #5F5F5F;
}
.p-contact .p-contacttypes__type:hover {
  text-decoration: underline;
}
.p-contact .p-contacttypes__icon {
  width: 30px;
  position: relative;
  top: 2px;
}
.p-contact .p-copyright {
  display: flex;
  margin: 100px 0 0;
  font-size: 10px;
  color: #5F5F5F;
}
.p-contact .p-copyright__socials {
  font-family: "FuturaLTPro-Medium", sans-serif;
  font-weight: 500;
  flex: 1;
  text-align: right;
  font-size: 12px;
}
.p-contact .p-copyright__site {
  font-family: "FuturaLTPro-Medium", sans-serif;
  font-weight: 500;
  font-size: 10px;
}
.p-contact .p-copyright__icons {
  margin-left: 5px;
}
.p-contact .p-copyright__icons a {
  margin-left: 10px;
}
@media (max-width: 768px) {
  .p-contact .p-copyright__socials svg {
    width: 15px;
    height: 15px;
  }
}
@media (max-width: 576px) {
  .p-contact .p-copyright {
    margin-top: 30px;
  }
}
.p-contact .p-organizations {
  padding-top: 373px;
}
.p-contact .p-organizations__divider {
  width: 100%;
  height: 1px;
  background: #979797;
  margin: 40px 0 30px;
}
.p-contact .p-organizations__organization {
  text-decoration: none;
  display: flex;
  align-items: center;
}
.p-contact .p-organizations__organization-arrow-container {
  flex: 1;
  text-align: right;
}
@media (max-width: 576px) {
  .p-contact .p-organizations {
    padding-top: 30px;
  }
}
@media (max-width: 1200px) {
  .p-contact {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media (max-width: 576px) {
  .p-contact {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 30px;
  }
}