.embeddedobject--site {
  margin: 60px 0;
}
.embeddedobject--button {
  margin-top: 40px;
}
@media (max-width: 576px) {
  .embeddedobject--site {
    margin: 30px 0;
  }
  .embeddedobject--button .button {
    width: 100%;
  }
}