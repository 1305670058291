.header__logo {
  position: absolute;
  top: 40px;
  left: 40px;
  background: url("../web/img/logo-black.2x.png") no-repeat;
  width: 75px;
  height: 75px;
  z-index: 1000;
  background-size: 100%;
  transition: width 0.3s, height 0.3s;
}
html.header-white .header__logo {
  background-image: url("../web/img/logo-white.2x.png");
}
html.logo-big .header__logo {
  width: 150px;
  height: 150px;
}
@media (max-width: 576px) {
  .header__logo {
    top: 25px;
    left: 25px;
  }
}