.menu {
  display: flex;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.4s;
  z-index: 2000;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #CAC9C9;
}
html.menu-open .menu {
  opacity: 1;
  pointer-events: auto;
}
.menu__container {
  width: 50vw;
  padding-bottom: 40px;
  min-width: 500px;
}
.menu__image {
  flex: 1;
}
.menu__items, .menu__item {
  list-style: none;
  padding: 0;
  margin: 0;
}
.menu__item {
  margin-bottom: 10px;
}
.menu__left {
  padding: 70px 0 0 calc((100vw - 1200px) / 2);
}
.menu__logo {
  display: inline-block;
  margin: 0 0 45px;
}
.menu__logo img {
  display: block;
}
.menu__link {
  font-family: "FuturaLTPro-Bold", sans-serif;
  font-weight: 700;
  font-size: 50px;
  color: #fff;
  line-height: 40px;
  text-decoration: none;
  position: relative;
}
.menu__link::after {
  content: "";
  position: absolute;
  left: -10px;
  width: 0;
  bottom: 7px;
  height: 12px;
  background: #0095DE;
  z-index: -1;
  transition: width 0.3s;
}
.menu__link--selected::after {
  width: calc(100% + 10px);
}
@media (max-width: 1220px) {
  .menu__left {
    padding-left: 25px;
  }
}
@media (min-width: 769px) {
  .menu__link:hover::after {
    width: calc(100% + 10px);
  }
}
@media (max-width: 768px) {
  .menu__link {
    font-size: 40px;
    line-height: 34px;
  }
  .menu__link::after {
    height: 7px;
    bottom: 5px;
  }
}
@media (max-width: 576px) {
  .menu__container {
    min-width: initial;
    width: 100vw;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
  .menu__image {
    display: none;
  }
  .menu__left {
    padding-top: 25px;
  }
  .menu__link {
    font-size: 36px;
    line-height: 30px;
  }
  .menu__link::after {
    height: 5px;
    bottom: 4px;
  }
}

@media (max-width: 768px) {
  html.menu-open body {
    overflow: hidden;
    overflow-y: scroll !important;
    position: fixed;
    width: 100%;
  }
}