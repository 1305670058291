html.page-story .header,
html.page-story .footer,
html.page-story .hamburger {
  display: none;
}

.p-stories {
  margin: 130px 0 50px;
}
.p-stories .p-pagetitle,
.p-stories .p-path {
  max-width: 792px;
  margin: 0 auto;
}
@media screen and (max-width: 792px) {
  .p-stories .p-pagetitle,
  .p-stories .p-path {
    padding: 0 25px;
  }
}
.p-stories .p-grid {
  max-width: 1200px;
  margin: 100px auto 0;
  display: flex;
  position: relative;
}
@media screen and (max-width: 1200px) {
  .p-stories .p-grid {
    padding: 0 25px;
  }
}
.p-stories .p-grid__col {
  width: 50%;
}
.p-stories .p-grid__col--left {
  padding-right: 78px;
}
.p-stories .p-grid__col--right {
  padding-left: 78px;
}
.p-stories .p-grid__col--right .p-story:first-child {
  margin-top: 200px;
}
.p-stories .p-grid__line {
  position: absolute;
  top: 0;
  left: 50%;
  width: 1px;
  height: 100%;
  background: #979797;
}
.p-stories .p-grid__marker {
  position: absolute;
  top: 0;
  left: 50%;
  background: url("../../web/img/timelinemarker.svg") no-repeat;
  width: 29px;
  height: 29px;
  margin: -15px 0 0 -15px;
  transition: top 1s, opacity 0.5s;
  z-index: 10;
  opacity: 0;
}
.p-stories .p-grid__marker.show {
  opacity: 1;
}
@media (max-width: 576px) {
  .p-stories .p-grid {
    display: block;
    margin-top: 25px;
  }
  .p-stories .p-grid__col {
    width: 100%;
  }
  .p-stories .p-grid__col--left {
    padding-right: 0;
  }
  .p-stories .p-grid__col--right {
    padding-left: 0;
  }
  .p-stories .p-grid__col--right .p-story:first-child {
    margin-top: 25px;
  }
  .p-stories .p-grid__line, .p-stories .p-grid__marker {
    display: none;
  }
}
.p-stories .p-story {
  margin: 0 0 90px;
}
.p-stories .p-story:last-child {
  margin-bottom: 0;
}
.p-stories .p-story__image {
  width: 100%;
}
.p-stories .p-story__info {
  display: flex;
  align-items: center;
  margin: 5px 0 20px;
  color: #9B9B9B;
}
.p-stories .p-story__date {
  color: #9B9B9B;
  margin: 0;
}
.p-stories .p-story__share {
  font-family: "FuturaLTPro-Medium", sans-serif;
  font-weight: 500;
  flex: 1;
  text-align: right;
  font-size: 12px;
  color: #000;
  padding-right: 10px;
}
.p-stories .p-story__shareicon {
  margin-left: 15px;
}
.p-stories .p-story__shareicon .iconcolor {
  fill: #000;
}
.p-stories .p-story__rtd > *:last-child {
  margin-bottom: 0;
}