.p-projectspage {
  margin: 130px 0 50px;
}
.p-projectspage .p-organizations {
  margin: 0 auto 50px;
  max-width: 1200px;
}
@media screen and (max-width: 1200px) {
  .p-projectspage .p-organizations {
    padding: 0 25px;
  }
}
.p-projectspage .p-organizations__logos {
  display: flex;
  flex-wrap: wrap;
  margin-top: -10px;
  margin-left: -10px;
}
.p-projectspage .p-organizations__logos .p-organizations__logo-container {
  flex: 1 0 150px;
  margin-top: 10px;
  margin-left: 10px;
}
@media (min-width: 320px) {
  .p-projectspage .p-organizations__logos .p-organizations__logo-container {
    max-width: calc(50% - 10px);
  }
}
@media (min-width: 480px) {
  .p-projectspage .p-organizations__logos .p-organizations__logo-container {
    max-width: calc(33.3333333333% - 10px);
  }
}
@media (min-width: 640px) {
  .p-projectspage .p-organizations__logos .p-organizations__logo-container {
    max-width: calc(25% - 10px);
  }
}
@media (min-width: 800px) {
  .p-projectspage .p-organizations__logos .p-organizations__logo-container {
    max-width: calc(20% - 10px);
  }
}
@media (min-width: 750px) {
  .p-projectspage .p-organizations__logos .p-organizations__logo-container {
    min-width: calc(20% - 10px);
  }
}
.p-projectspage .p-organizations__logo {
  width: auto;
  height: 115px;
  display: inline-block;
}
.p-projectspage .p-projects,
.p-projectspage .p-path {
  max-width: 792px;
  margin: 0 auto;
}
@media screen and (max-width: 792px) {
  .p-projectspage .p-projects,
  .p-projectspage .p-path {
    padding: 0 25px;
  }
}
.p-projectspage .p-projects__grid .grid-sizer,
.p-projectspage .p-projects__grid .grid-item {
  width: calc(50% - 35px);
}
.p-projectspage .p-projects__grid .grid-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}
.p-projectspage .p-projects__image {
  width: 100%;
  height: 492px;
}
.p-projectspage .p-projects__projectdescription {
  font-family: "FuturaLTPro-Medium", sans-serif;
  font-weight: 500;
  padding: 25px;
  font-size: 18px;
  color: #000;
}
.p-projectspage .p-projects__projecttitle {
  margin: 0 0 8px;
}
.p-projectspage .p-projects__projecttype {
  font-family: "FuturaLTPro-Book", sans-serif;
  font-weight: 300;
  font-size: 16px;
}
.p-projectspage .p-projects__projectarrow {
  margin: 0 0 0 20px;
}
.p-projectspage .p-projects__projectblock {
  text-decoration: none;
}
@media (max-width: 768px) {
  .p-projectspage .p-projects {
    flex-direction: column;
    padding-right: 25px;
    padding-left: 25px;
  }
  .p-projectspage .p-projects .p-projects__grid .grid-sizer,
  .p-projectspage .p-projects .p-projects__grid .grid-item {
    width: 100%;
  }
}